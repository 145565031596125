import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-grid-system';
import useDeviceSize from '../../hooks/use-device-size';
import NestedAccordion from '../Accordion/nestedAccordion';
import "./tabAccordion.scss";
import { useLocation } from "@reach/router";
import { Context } from '../../redux/store';
import { useContext } from 'react';

function TabAccordion({ data, children }) {
  const deviceSize = useDeviceSize();
  const [active, setActive] = useState();
  const location = useLocation();
  const { state } = useContext(Context);

  const handleTabChange = (index) => {
    deviceSize.mdDown && setActive(index);
    navigate(`${location.pathname}#tab${index + 1}`)
  }

  const handleKeyDown = (event, index) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleTabChange(index);
    }
  }

  useEffect(() => {
    setActive(deviceSize.mdDown ? undefined : 0)
  }, [deviceSize.mdDown]);
  
  useEffect(() => {
    !deviceSize.mdDown && state.activeId && setActive(Number(state.activeId?.split('tab')[1]) - 1);
  }, [state.activeId, deviceSize.mdDown]);

  return (
    <>
      <Row className='tabAccordion'>
        <Col lg={3} style={{ display: deviceSize.mdUp ? '' : 'none' }}>
          <div className="sticky-wrap">
            {children[0]?.props?.children?.map((item, index) => (
              <div
                className={`${index === active ? 'tab-title-active' : ''}`}
                onClick={() => handleTabChange(index)}
                onKeyDown={(event) => handleKeyDown(event, index)}
                role="button"
                tabIndex={0}
              >
                {item}
              </div>
            ))}
          </div>
        </Col>
        <Col lg={9}>
          {children?.map((item, index) => (
            index !== 0 && <NestedAccordion
              tabView={deviceSize.mdUp}
              data={{ ...data, heading: children[0]?.props?.children[index - 1]?.props?.children }}
              active={deviceSize.mdDown ? (active === index - 1) : true}
            >
              {item}
            </NestedAccordion>
          ))}
        </Col>
      </Row>
    </>
  )
}

export default TabAccordion;
