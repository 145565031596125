import React from 'react'

function TabAccordionList({ children }) {
  return (
    <div className='tabAccordionList'>
      {children}
    </div>
  )
}

export default TabAccordionList