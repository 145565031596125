import React, { useRef, useState, useEffect } from "react";
import "./customAccordion.scss";

import { triggerCustomGTM } from "../../utils/helper";

function CustomAccordion({ data, parentOpen, customTagTextSub }) {
    const [open, setOpen] = useState(false);
  
    const handleButtonClick = () => {
      setOpen(!open);
      if (customTagTextSub) {
        if (!open) {
          triggerCustomGTM({
            event: "gtm.click",
            customTag: customTagTextSub,
          });
          // console.log(customTagTextSub);
        }
      }
    };
  
    const contentRef = useRef(null);
    let height = "0px";
    useEffect(() => {
      setOpen(false);
    }, [parentOpen]);
  
    height = open ? `${contentRef.current.scrollHeight}px` : "0px";
  
    return (
      <div className="custom-accordion__wrapper">
        <button className="accordion-title" onClick={handleButtonClick}>
          <span className="accordion__plus-minus">{open ? "–" : "+"}</span>
          <h3
            className="mb-0 font-18"
            dangerouslySetInnerHTML={{ __html: data.heading }}
          />
        </button>
        <div
          ref={contentRef}
          style={{ maxHeight: `${height}` }}
          className="accordion-body"
        >
          <div dangerouslySetInnerHTML={{ __html: data.content }} />
        </div>
      </div>
    );
  }
  
  export default CustomAccordion;
  