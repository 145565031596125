
import React, { useEffect, useRef, useState } from 'react'
import { useContext } from 'react';
import { Context } from '../../redux/store';


function TabAccordionPanel({ children, id }) {
  const myRef = useRef();
  const [offset, setOffset] = useState(0);
  const { dispatch } = useContext(Context);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);
  useEffect(() => {
    const a = myRef.current.getBoundingClientRect()
    if (a.top < 200) {
      dispatch({ type: "SET_ACTIVE_ID", payload: id, })
    }
  }, [offset, dispatch, id])

  return (
    <div ref={myRef} className='tacAccordionPanel' id={id}>
      {children}
    </div>
  )
}

export default TabAccordionPanel