import React, { useEffect } from "react";
import useCollapse from "react-collapsed";
import CustomAccordion from "./customAccordion";
import { TfiAngleDown, TfiAngleUp } from "react-icons/tfi";
import "./nestedAccordion.scss";

import { triggerCustomGTM } from "../../utils/helper";

function NestedAccordion({
  data,
  active,
  children,
  tabView,
  openIcon,
  customTagText,
}) {
  const [isExpanded, setExpanded] = React.useState(active);
  const { getToggleProps, getCollapseProps } = useCollapse({
    isExpanded,
    duration: 1000,
  });
  useEffect(() => {
    setExpanded(active);
  }, [active]);
  if (tabView) {
    return (
      <div className={`tab__section ${isExpanded ? "tab-content-active" : ""}`}>
        {children && children}
      </div>
    );
  }
  return (
    <div className="accordion__section">
      <div
        {...getToggleProps({
          style: {},
          onClick: () => {
            setExpanded((x) => !x);
            if (customTagText) {
              if (!isExpanded) {
                triggerCustomGTM({
                  event: "gtm.click",
                  customTag: customTagText,
                });
                // console.log(customTagText);
              }
            }
          },
        })}
        className={`accordion ${isExpanded ? "active" : ""}`}
      >
        <p
          className="accordion__title"
          dangerouslySetInnerHTML={{ __html: data.heading }}
        />
        <span className="accordion__icon">
          {isExpanded ? <TfiAngleUp /> : <TfiAngleDown />}
        </span>
      </div>

      <div {...getCollapseProps({ style: {} })}>
        {children && children}
        {data.innerAccordion?.map((d) => (
          <CustomAccordion
            data={d}
            parentOpen={isExpanded}
            customTagTextSub={d.customTagTitleSub}
          />
        ))}
      </div>
    </div>
  );
}

export default NestedAccordion;
